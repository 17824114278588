import { CoreModel, EntityMapModel, ISelectOption, SettingsTypeModel } from '@wings-shared/core';
import { IAPICommunication } from '../Interfaces/API-CustomerContact.interface';
import { CommunicationAssociationModel } from './CustomerCommunicationAssociation.model';

export class CustomerCommunicationModel extends CoreModel implements ISelectOption {
  communicationLevel: SettingsTypeModel;
  contactRole: SettingsTypeModel | null;
  startDate: string;
  endDate: string;
  communicationCategories: SettingsTypeModel[] = [];
  customerAssociation: CommunicationAssociationModel;
  operatorAssociations: EntityMapModel[] = [];
  registryAssociations: EntityMapModel[] = [];

  constructor(data?: Partial<CustomerCommunicationModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: IAPICommunication): CustomerCommunicationModel {
    if (!apiData) {
      return new CustomerCommunicationModel();
    }
    const data: Partial<CustomerCommunicationModel> = {
      ...apiData,
      id: apiData.customerCommunicationId,
      communicationLevel: new SettingsTypeModel({
        ...apiData.communicationLevel,
        id: apiData.communicationLevel.communicationLevelId,
      }),
      contactRole: apiData.contactRole
        ? new SettingsTypeModel({
          ...apiData.contactRole,
          id: apiData.contactRole.contactRoleId,
        })
        : null,
      startDate: apiData.startDate,
      endDate: apiData.endDate,
      communicationCategories:
        apiData.communicationCategories?.map(a => new SettingsTypeModel({ ...a, id: a.communicationCategoryId })) || [],
      customerAssociation: CommunicationAssociationModel.deserialize(apiData.customerCommunicationAssociation),
      operatorAssociations:
        apiData.operatorCommunicationAssociations?.map(
          entity =>
            new EntityMapModel({
              id: entity.operatorCommunicationAssociationId,
              entityId: entity.operator?.operatorId,
              name: entity.operator?.name,
            })
        ) || [],
      registryAssociations:
        apiData.registryCommunicationAssociations?.map(
          entity =>
            new EntityMapModel({
              id: entity.registryCommunicationAssociationId,
              entityId: entity.registry?.registryId,
              name: entity.registry?.name,
            })
        ) || [],
    };
    return new CustomerCommunicationModel(data);
  }

  static deserializeList(apiDataList: IAPICommunication[]): CustomerCommunicationModel[] {
    return apiDataList ? apiDataList.map(apiData => CustomerCommunicationModel.deserialize(apiData)) : [];
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
