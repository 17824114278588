import { CoreModel, EntityMapModel, SettingsTypeModel } from '@wings-shared/core';
import { CommunicationAssociationModel } from '../CustomerCommunicationAssociation.model';
import { IAPICommunicationFlatView } from '../../Interfaces';

export class ContactCommunicationFlatViewModel extends CoreModel {
  contact: string;
  contactExtension: string;
  contactName: string;
  contactMethod: SettingsTypeModel;
  contactType: SettingsTypeModel;

  // Communication
  startDate: string;
  endDate: string;
  contactRole: SettingsTypeModel | null;
  communicationLevel: SettingsTypeModel;
  communicationCategories: SettingsTypeModel[] = [];
  customerAssociation: CommunicationAssociationModel;
  operatorAssociations: EntityMapModel[] = [];
  registryAssociations: EntityMapModel[] = [];

  constructor(data?: Partial<ContactCommunicationFlatViewModel>) {
    super();
    Object.assign(this, data);
  }

  static deserialize(apiData: IAPICommunicationFlatView): ContactCommunicationFlatViewModel {
    const data: Partial<ContactCommunicationFlatViewModel> = {
      ...apiData,
      id: apiData.contactId || apiData.id,
      contact: apiData.contactValue,
      contactMethod: new SettingsTypeModel({ ...apiData.contactMethod, id: apiData.contactMethod?.contactMethodId }),
      contactType: new SettingsTypeModel({ ...apiData.contactType, id: apiData.contactType?.contactTypeId }),

      // communication fields
      contactRole: new SettingsTypeModel({ ...apiData.contactRole, id: apiData.contactRole?.contactRoleId }),
      communicationLevel: new SettingsTypeModel({
        ...apiData.communicationLevel,
        id: apiData.communicationLevel?.communicationLevelId,
      }),
      communicationCategories:
        apiData.communicationCategories?.map(a => new SettingsTypeModel({ ...a, id: a.communicationCategoryId })) || [],
      ...this.deserializeAuditFields(apiData),
      customerAssociation: CommunicationAssociationModel.deserialize(apiData.customerCommunicationAssociation),
      operatorAssociations:
        apiData.operatorCommunicationAssociations?.map(
          entity =>
            new EntityMapModel({
              id: entity.operatorCommunicationAssociationId,
              entityId: entity.operator?.operatorId,
              name: entity.operator?.name,
            })
        ) || [],
      registryAssociations:
        apiData.registryCommunicationAssociations?.map(
          entity =>
            new EntityMapModel({
              id: entity.registryCommunicationAssociationId,
              entityId: entity.registry?.registryId,
              name: entity.registry?.name,
            })
        ) || [],
    };
    return new ContactCommunicationFlatViewModel(data);
  }
}
