import React, { FC, ReactNode, useState } from 'react';
import { Typography } from '@material-ui/core';
import { UpsertSettings } from '@wings/shared';
import { SelectOption, regex } from '@wings-shared/core';
import { observer, inject } from 'mobx-react';
import { SETTING_ID, SettingsStore } from '../Shared';
import { useStyles } from './Settings.style';
import { categoryList, settingList } from './Fields';
import { SettingCategoryControl } from '@wings-shared/form-controls';
import { ServiceType, Team } from './Components';

interface Props {
  settingsStore?: SettingsStore;
}

const Settings: FC<Props> = ({ settingsStore }: Props) => {
  const [ activeCategory, setActiveCategory ] = useState<number>(1);
  const [ activeSubCategory, setActiveSubCategory ] = useState<number>(1);
  const classes = useStyles();
  const _settingsStore = settingsStore as SettingsStore;

  const subCategories = (category?: number): SelectOption[] => {
    return settingList
      .filter(setting => setting.categoryId === (category || activeCategory))
      .map(setting => new SelectOption({ name: setting.settingLabel, value: setting.settingId }));
  };

  const onCategoryChange = (categoryID: number): void => {
    setActiveCategory(categoryID);
    setActiveSubCategory(subCategories(categoryID)[0].value as number);
  };

  const onSubCategoryChange = (categoryID: number): void => {
    setActiveSubCategory(categoryID);
  };

  const renderSetting = (): ReactNode => {
    switch (activeSubCategory) {
      case SETTING_ID.SOURCE_TYPE:
        return (
          <UpsertSettings
            key="SourceType"
            type="Source Type"
            upsertSettings={data => _settingsStore.upsertSourceType(data)}
            getSettings={() => _settingsStore.getSourceTypes()}
            settingsData={_settingsStore.sourceTypes}
            maxLength={100}
          />
        );
      case SETTING_ID.ACCESS_LEVEL:
        return (
          <UpsertSettings
            key="AccessLevel"
            type="Access Level"
            upsertSettings={data => _settingsStore.upsertAccessLevel(data)}
            getSettings={() => _settingsStore.getAccessLevels()}
            settingsData={_settingsStore.accessLevels}
            maxLength={50}
          />
        );
      case SETTING_ID.SERVICE_TYPE:
        return <ServiceType />;
      case SETTING_ID.TEAM:
        return <Team />;
      case SETTING_ID.SPECIAL_CARE_TYPE:
        return (
          <UpsertSettings
            key="SpecialCareType"
            type="Special Care Type"
            upsertSettings={data => _settingsStore.upsertSpecialCareType(data)}
            getSettings={() => _settingsStore.getSpecialCareType()}
            settingsData={_settingsStore.specialCareType}
            maxLength={50}
          />
        );
      case SETTING_ID.SPECIAL_CARE_TYPE_LEVEL:
        return (
          <UpsertSettings
            key="SpecialCareTypeLevel"
            type="Special Care Type Level"
            upsertSettings={data => _settingsStore.upsertSpecialCareTypeLevel(data)}
            getSettings={() => _settingsStore.getSpecialCareTypeLevel()}
            settingsData={_settingsStore.specialCareTypeLevel}
            maxLength={50}
          />
        );
      case SETTING_ID.CONTACT_METHOD:
        return (
          <UpsertSettings
            key="ContactMethod"
            type="Contact Method"
            upsertSettings={data => _settingsStore.upsertContactMethod(data)}
            getSettings={() => _settingsStore.getContactMethod()}
            settingsData={_settingsStore.contactMethod}
          />
        );
      case SETTING_ID.CONTACT_TYPE:
        return (
          <UpsertSettings
            key="ContactType"
            type="Contact Type"
            upsertSettings={data => _settingsStore.upsertContactType(data)}
            getSettings={() => _settingsStore.getContactType()}
            settingsData={_settingsStore.contactType}
          />
        );
      case SETTING_ID.COMMUNICATION_CATEGORIES:
        return (
          <UpsertSettings
            key="CommunicationCategories"
            type="Communication Categories"
            upsertSettings={data => _settingsStore.upsertCommunicationCategories(data)}
            getSettings={() => _settingsStore.getCommunicationCategories()}
            settingsData={_settingsStore.communicationCategories}
            maxLength={50}
            regExp={regex.alphabetWithHyphen}
          />
        );
      case SETTING_ID.CONTACT_ROLE:
        return (
          <UpsertSettings
            key="ContactRole"
            type="Contact Role"
            upsertSettings={data => _settingsStore.upsertContactRole(data)}
            getSettings={() => _settingsStore.getContactRole()}
            settingsData={_settingsStore.contactRole}
            maxLength={50}
            regExp={regex.alphabetWithSlash}
          />
        );
      case SETTING_ID.COMMUNICATION_LEVEL:
        return (
          <UpsertSettings
            key="CommunicationLevel"
            type="Communication Level"
            upsertSettings={data => _settingsStore.upsertCommunicationLevel(data)}
            getSettings={() => _settingsStore.getCommunicationLevel()}
            settingsData={_settingsStore.communicationLevel}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={classes.heading}>
        <Typography component="h3">Customer Settings</Typography>
      </div>
      <div className={classes.root}>
        <div className={classes.selectSettingContainer}>
          <SettingCategoryControl
            title="Setting category"
            value={activeCategory}
            selectOptions={categoryList}
            onOptionChange={onCategoryChange}
          />
          <SettingCategoryControl
            title="Sub category"
            value={activeSubCategory}
            selectOptions={subCategories()}
            onOptionChange={onSubCategoryChange}
          />
        </div>
        <div className={classes.settingWrapper}>{renderSetting()}</div>
      </div>
    </>
  );
};

export default inject('settingsStore')(observer(Settings));
export { Settings as PureSettings };
