import React from 'react';
import { INavigationLink } from '@wings-shared/layout';
import {
  InfoIcon,
  AirplaneIcon,
  PeopleIcon,
  SettingIcon,
  HandlerCloneIcon,
  WorldIcon,
  ContactBookIcon,
  PhoneIcon,
} from '@uvgo-shared/icons';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BeenhereIcon from '@material-ui/icons/Beenhere';

/* istanbul ignore next */
export const customerSidebarOptions = (
  defaultOptions: boolean,
  isCustomerOptions?: boolean,
  isDisabled?: boolean
): INavigationLink[] => {
  if (isCustomerOptions) {
    return upsertCustomerOptions(isDisabled || false);
  }
  return defaultOptions ? defaultCustomerOptions : operatorOptions(isDisabled || false);
};

export const upsertAssociatedRegistryBackNavLink = (idQuery: string | number, viewMode: string) =>
  `/customer/upsert/${idQuery}/${viewMode}/associated-registries`;

export const defaultCustomerOptions: INavigationLink[] = [
  { to: '', title: 'Customer', icon: <PeopleIcon size="medium" /> },
  { to: 'registry', title: 'Registry', icon: <AirplaneIcon /> },
  { to: 'operator', title: 'Operator', icon: <HandlerCloneIcon /> },
  { to: 'contacts', title: 'Contacts', icon: <ContactBookIcon /> },
  { to: 'communications', title: 'Communications', icon: <PhoneIcon /> },
  {
    to: 'settings',
    title: 'Settings',
    icon: <SettingIcon />,
  },
];

const upsertCustomerOptions = (isDisabled: boolean): INavigationLink[] => [
  { to: '', title: 'General Information', icon: <InfoIcon /> },
  { to: 'associated-registries', title: 'Associated Registries', icon: <AirplaneIcon />, isDisabled },
  { to: 'associated-operators', title: 'Associated Operators', icon: <HandlerCloneIcon />, isDisabled },
  { to: 'associated-bill-to-sites', title: 'Associated Bill To Sites', icon: <WorldIcon />, isDisabled },
  { to: 'associated-special-care', title: 'Associated Special Care/Inform', icon: <BeenhereIcon />, isDisabled },
  { to: 'associated-office', title: 'Associated Office', icon: <ApartmentIcon />, isDisabled },
  { to: 'manage-registries', title: 'Manage Registries', icon: <AirplaneIcon />, isDisabled },
];

const operatorOptions = (isDisabled: boolean): INavigationLink[] => [
  { to: '', title: 'General Information', icon: <InfoIcon /> },
  { to: 'associated-customers', title: 'Associated Customers', icon: <PeopleIcon size="medium" />, isDisabled },
];

export const registryOptions = (isDisabled: boolean): INavigationLink[] => [
  { to: '', title: 'General Information', icon: <InfoIcon /> },
  { to: 'associated-customers', title: 'Associated Customers', icon: <PeopleIcon size="medium" />, isDisabled },
  { to: 'customs-decal', title: 'Customs Decal', icon: <PeopleIcon size="medium" />, isDisabled },
];

export const associatedSiteSidebarOptions = (): INavigationLink[] => [
  { to: '', title: 'General Information', icon: <InfoIcon /> },
];

export const associatedRegistrySidebarOptions = (isDisabled?: boolean): INavigationLink[] => [
  { to: '', title: 'General Information', icon: <InfoIcon /> },
  { to: 'sites', title: 'Sites', icon: <WorldIcon />, isDisabled },
];

export const customerCommunicationOptions = (isDisabled: boolean): INavigationLink[] => [
  { to: '', title: 'General Information', icon: <InfoIcon /> },
];
