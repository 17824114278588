export enum SETTING_CATEGORIES {
  GENERAL = 1,
  CUSTOMER,
  CUSTOMER_COMMS,
}

export enum SETTING_ID {
  ACCESS_LEVEL = 1,
  SOURCE_TYPE,
  SERVICE_TYPE,
  TEAM,
  SPECIAL_CARE_TYPE,
  SPECIAL_CARE_TYPE_LEVEL,
  CONTACT_METHOD,
  CONTACT_TYPE,
  COMMUNICATION_CATEGORIES,
  CONTACT_ROLE,
  COMMUNICATION_LEVEL,
}
