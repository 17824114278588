export const apiUrls = {
  test: 'api/test',
  registry: 'api/Registry',
  manageRegistries: customerNumber => `${customerNumber}/manageRegistries`,
  customer: 'api/Customer',
  operator: 'api/Operator',
  associatedRegistry: 'registry',
  associatedOperator: 'operator',
  associatedSites: 'api/CustomerAssociatedSites',
  customerAssociatedSpecialCare: id => `api/customer/${id}/CustomerAssociatedSpecialCare`,
  customerAssociatedOffice: id => `api/customer/${id}/CustomerAssociatedOffice`,
  customerAssociatedRegistrySite: (id, customerassociatedregistryId) =>
    `api/customer/${id}/customerassociatedregistry/${customerassociatedregistryId}/CustomerAssociatedRegistrySite`,
  referenceData: 'api/referenceData',
  serviceType: 'api/ServiceType',
  team: 'api/Team',
  specialCareType: 'api/SpecialCareType',
  specialCareTypeLevel: 'api/SpecialCareTypeLevel',
  customsDecal: 'api/CustomsDecal',
  contactMethod: 'api/ContactMethod',
  contactType: 'api/ContactType',
  communicationCategory: 'api/CommunicationCategory',
  contactRole: 'api/contactRole',
  contact:'api/Contact',
  communicationLevel: 'api/CommunicationLevel',
  priority: 'api/Priority',
};
