import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { observable } from 'mobx';
import { baseApiPath, HttpClient, SettingsBaseStore } from '@wings/shared';
import { Utilities, SettingsTypeModel, tapWithAction } from '@wings-shared/core';
import { AlertStore } from '@uvgo-shared/alert';
import { Logger } from '@wings-shared/security';
import { apiUrls } from './API.url';
import { TeamModel } from '../Models';

export class SettingsStore extends SettingsBaseStore {
  @observable public serviceType: SettingsTypeModel[] = [];
  @observable public specialCareType: SettingsTypeModel[] = [];
  @observable public specialCareTypeLevel: SettingsTypeModel[] = [];
  @observable public teams: TeamModel[] = [];
  @observable public contactMethod: SettingsTypeModel[] = [];
  @observable public contactType: SettingsTypeModel[] = [];
  @observable public communicationCategories: SettingsTypeModel[] = [];
  @observable public contactRole: SettingsTypeModel[] = [];
  @observable public communicationLevel: SettingsTypeModel[] = [];

  constructor() {
    super(baseApiPath.customer);
  }

  /* istanbul ignore next */
  public getServiceType(forceRefresh: boolean = false): Observable<SettingsTypeModel[]> {
    return this.getResult(apiUrls.serviceType, this.serviceType, forceRefresh, SettingsTypeModel.deserializeList).pipe(
      tap(serviceType => (this.serviceType = serviceType))
    );
  }

  /* istanbul ignore next */
  public upsertServiceType(request: SettingsTypeModel): Observable<SettingsTypeModel> {
    const isAddServiceType: boolean = request.id === 0;
    return this.upsert(request, apiUrls.serviceType, 'Service Type').pipe(
      map(response => SettingsTypeModel.deserialize(response)),
      tap((serviceType: SettingsTypeModel) => {
        this.serviceType = Utilities.updateArray<SettingsTypeModel>(this.serviceType, serviceType, {
          replace: !isAddServiceType,
          predicate: t => t.id === serviceType.id,
        });
      })
    );
  }

  /* istanbul ignore next */
  public removeServiceType(request: SettingsTypeModel): Observable<string> {
    const params = {
      serviceTypeId: request.id,
    };
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.customer });
    return http.delete<string>(`${apiUrls.serviceType}`, params).pipe(
      Logger.observableCatchError,
      map((response: any) => response),
      tap(() => AlertStore.info('Service Type deleted successfully!'))
    );
  }

  /* istanbul ignore next */
  public getTeams(forceRefresh: boolean = false): Observable<TeamModel[]> {
    return this.getResult(apiUrls.team, this.teams, forceRefresh, TeamModel.deserializeList).pipe(
      tapWithAction(teams => (this.teams = teams))
    );
  }

  /* istanbul ignore next */
  public upsertTeams(request: TeamModel): Observable<TeamModel> {
    const isNewRequest: boolean = request.id === 0;
    return this.upsert(request.serialize(), apiUrls.team, 'Team').pipe(
      map(response => TeamModel.deserialize(response)),
      tapWithAction((team: TeamModel) => {
        this.teams = Utilities.updateArray<TeamModel>(this.teams, team, {
          replace: !isNewRequest,
          predicate: t => t.id === team.id,
        });
      })
    );
  }

  /* istanbul ignore next */
  public getSpecialCareType(forceRefresh: boolean = false): Observable<SettingsTypeModel[]> {
    return this.getResult(
      apiUrls.specialCareType,
      this.specialCareType,
      forceRefresh,
      SettingsTypeModel.deserializeList
    ).pipe(tap(specialCareType => (this.specialCareType = specialCareType)));
  }

  /* istanbul ignore next */
  public upsertSpecialCareType(request: SettingsTypeModel): Observable<SettingsTypeModel> {
    const isAddSpecialCareType: boolean = request.id === 0;
    return this.upsert(request, apiUrls.specialCareType, 'Special Care Type').pipe(
      map(response => SettingsTypeModel.deserialize(response)),
      tap((specialCareType: SettingsTypeModel) => {
        this.specialCareType = Utilities.updateArray<SettingsTypeModel>(this.specialCareType, specialCareType, {
          replace: !isAddSpecialCareType,
          predicate: t => t.id === specialCareType.id,
        });
      })
    );
  }

  /* istanbul ignore next */
  public getSpecialCareTypeLevel(forceRefresh: boolean = false): Observable<SettingsTypeModel[]> {
    return this.getResult(
      apiUrls.specialCareTypeLevel,
      this.specialCareTypeLevel,
      forceRefresh,
      SettingsTypeModel.deserializeList
    ).pipe(tap(specialCareTypeLevel => (this.specialCareTypeLevel = specialCareTypeLevel)));
  }

  /* istanbul ignore next */
  public upsertSpecialCareTypeLevel(request: SettingsTypeModel): Observable<SettingsTypeModel> {
    const isAddSpecialCareTypeLevel: boolean = request.id === 0;
    return this.upsert(request, apiUrls.specialCareTypeLevel, 'Special Care Type Level').pipe(
      map(response => SettingsTypeModel.deserialize(response)),
      tap((specialCareTypeLevel: SettingsTypeModel) => {
        this.specialCareTypeLevel = Utilities.updateArray<SettingsTypeModel>(
          this.specialCareTypeLevel,
          specialCareTypeLevel,
          {
            replace: !isAddSpecialCareTypeLevel,
            predicate: t => t.id === specialCareTypeLevel.id,
          }
        );
      })
    );
  }

  /* istanbul ignore next */
  public getContactMethod(forceRefresh: boolean = false): Observable<SettingsTypeModel[]> {
    return this.getResult(
      apiUrls.contactMethod,
      this.contactMethod,
      forceRefresh,
      SettingsTypeModel.deserializeList
    ).pipe(tap(contactMethod => (this.contactMethod = contactMethod)));
  }

  /* istanbul ignore next */
  public upsertContactMethod(request: SettingsTypeModel): Observable<SettingsTypeModel> {
    const isAddContactMethod: boolean = request.id === 0;
    return this.upsert(request, apiUrls.contactMethod, 'Contact Method').pipe(
      map(response => SettingsTypeModel.deserialize(response)),
      tap((contactMethod: SettingsTypeModel) => {
        this.contactMethod = Utilities.updateArray<SettingsTypeModel>(this.contactMethod, contactMethod, {
          replace: !isAddContactMethod,
          predicate: t => t.id === contactMethod.id,
        });
      })
    );
  }

  /* istanbul ignore next */
  public getContactType(forceRefresh: boolean = false): Observable<SettingsTypeModel[]> {
    return this.getResult(apiUrls.contactType, this.contactType, forceRefresh, SettingsTypeModel.deserializeList).pipe(
      tap(contactType => (this.contactType = contactType))
    );
  }

  /* istanbul ignore next */
  public upsertContactType(request: SettingsTypeModel): Observable<SettingsTypeModel> {
    const isAddContactType: boolean = request.id === 0;
    return this.upsert(request, apiUrls.contactType, 'Contact Type').pipe(
      map(response => SettingsTypeModel.deserialize(response)),
      tap((contactType: SettingsTypeModel) => {
        this.contactType = Utilities.updateArray<SettingsTypeModel>(this.contactType, contactType, {
          replace: !isAddContactType,
          predicate: t => t.id === contactType.id,
        });
      })
    );
  }

  /* istanbul ignore next */
  public getCommunicationCategories(forceRefresh: boolean = false): Observable<SettingsTypeModel[]> {
    return this.getResult(
      apiUrls.communicationCategory,
      this.communicationCategories,
      forceRefresh,
      SettingsTypeModel.deserializeList
    ).pipe(tap(communicationCategories => (this.communicationCategories = communicationCategories)));
  }

  /* istanbul ignore next */
  public upsertCommunicationCategories(request: SettingsTypeModel): Observable<SettingsTypeModel> {
    const isAddCommunicationCategory: boolean = request.id === 0;
    return this.upsert(request, apiUrls.communicationCategory, 'Communication Categories').pipe(
      map(response => SettingsTypeModel.deserialize(response)),
      tap((communicationCategory: SettingsTypeModel) => {
        this.communicationCategories = Utilities.updateArray<SettingsTypeModel>(
          this.communicationCategories,
          communicationCategory,
          {
            replace: !isAddCommunicationCategory,
            predicate: t => t.id === communicationCategory.id,
          }
        );
      })
    );
  }

  /* istanbul ignore next */
  public getContactRole(forceRefresh: boolean = false): Observable<SettingsTypeModel[]> {
    return this.getResult(apiUrls.contactRole, this.contactRole, forceRefresh, SettingsTypeModel.deserializeList).pipe(
      tap(contactRole => (this.contactRole = contactRole))
    );
  }

  /* istanbul ignore next */
  public upsertContactRole(request: SettingsTypeModel): Observable<SettingsTypeModel> {
    const isAddContactRole: boolean = request.id === 0;
    return this.upsert(request, apiUrls.contactRole, 'Contact Role').pipe(
      map(response => SettingsTypeModel.deserialize(response)),
      tap((contactRole: SettingsTypeModel) => {
        this.contactRole = Utilities.updateArray<SettingsTypeModel>(this.contactRole, contactRole, {
          replace: !isAddContactRole,
          predicate: t => t.id === contactRole.id,
        });
      })
    );
  }

  /* istanbul ignore next */
  public getCommunicationLevel(forceRefresh: boolean = false): Observable<SettingsTypeModel[]> {
    return this.getResult(
      apiUrls.communicationLevel,
      this.communicationLevel,
      forceRefresh,
      SettingsTypeModel.deserializeList
    ).pipe(tap(communicationLevel => (this.communicationLevel = communicationLevel)));
  }

  /* istanbul ignore next */
  public upsertCommunicationLevel(request: SettingsTypeModel): Observable<SettingsTypeModel> {
    const isAddCommunicationLevel: boolean = request.id === 0;
    return this.upsert(request, apiUrls.communicationLevel, 'Contact Level').pipe(
      map(response => SettingsTypeModel.deserialize(response)),
      tap((communicationLevel: SettingsTypeModel) => {
        this.communicationLevel = Utilities.updateArray<SettingsTypeModel>(
          this.communicationLevel,
          communicationLevel,
          {
            replace: !isAddCommunicationLevel,
            predicate: t => t.id === communicationLevel.id,
          }
        );
      })
    );
  }
}
