import { SearchStore, NotFoundPage } from '@wings-shared/core';
import { AuthStore, SettingsModuleSecurity } from '@wings-shared/security';
import { IBaseModuleProps } from '@wings/shared';
import { inject } from 'mobx-react';
import React, { Component, ReactNode } from 'react';
import { ContactCommsGeneralInfo, CustomerModuleSecurity, customerSidebarOptions } from './Modules/Shared';
import { Routes, Route } from 'react-router';
import { Operator, Settings, Registry, UpsertCustomer, Contacts, Communications } from './Modules';
import { UpsertRegistry } from './Modules/Registry/Components';
import { UpsertOperator } from './Modules/Operator/Components';

const CoreModule = React.lazy(() =>
  import(
    /* webpackChunkName: "customer-core-module" */
    './Modules/Core/Core.module'
  )
);

@inject('sidebarStore')
class CustomerApp extends Component<IBaseModuleProps> {
  private readonly redirectPath: string = '/customer';

  constructor(props) {
    super(props);
    this.props.sidebarStore?.setNavLinks(customerSidebarOptions(true), this.props.basePath);
    // Needs to set permissions after lazy loading
    SettingsModuleSecurity.updatePermissions();
    CustomerModuleSecurity.init();
    AuthStore.configureAgGrid();
  }

  // 81904 clear search store on application unmount
  componentWillUnmount(): void {
    SearchStore.clearSearch();
  }

  public render(): ReactNode {
    return (
      <Routes>
        <Route path="customer/*">
          <Route index element={<CoreModule />} />
          <Route path="upsert/:customerNumber/:viewMode/*" element={<UpsertCustomer key="customer-details" />} />
          <Route path="registry" element={<Registry />} />
          <Route path="registry/:viewMode" key="registryNew" element={<UpsertRegistry key="registry-new" />} />
          <Route path="registry/:registryId/:viewMode/*" element={<UpsertRegistry key="registry-details" />} />
          <Route path="operator" element={<Operator />} />
          <Route path="operator/:operatorId/:viewMode/*" element={<UpsertOperator key="operator-details" />} />

          <Route path="contacts" element={<Contacts />} />
          <Route path="contacts/:viewMode" key="contacts-new" element={<ContactCommsGeneralInfo />} />
          <Route
            path="contacts/:contactId/communication/:communicationId/:viewMode"
            key="contacts-edit-details"
            element={<ContactCommsGeneralInfo />}
          />

          <Route path="communications" element={<Communications />} />
          <Route
            path="communications/:viewMode"
            key="communication-new"
            element={<ContactCommsGeneralInfo isCommunicationView={true} />}
          />
          <Route
            path="communications/:communicationId/contact/:contactId/:viewMode"
            key="communication-edit-details"
            element={<ContactCommsGeneralInfo isCommunicationView={true} />}
          />

          <Route path="settings" element={<Settings />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    );
  }
}

export default CustomerApp;
