import { CoreModel, EntityMapModel, ISelectOption } from '@wings-shared/core';
import { ICommunicationAssociation } from '../Interfaces/API-CustomerContact.interface';
import { ICommunicationAssociationRequest } from '../Interfaces/ICustomerCommRequest.interface';
import { CustomerCommunicationAssociatedSitesModel } from './CustomerCommunicationAssociatedSites.model';
import { CustomerRefModel } from '@wings/shared';

export class CommunicationAssociationModel extends CoreModel implements ISelectOption {
  customer: CustomerRefModel;
  offices: EntityMapModel[];
  operators: EntityMapModel[];
  registries: EntityMapModel[];
  sites: CustomerCommunicationAssociatedSitesModel[];

  constructor(data?: Partial<CommunicationAssociationModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: ICommunicationAssociation): CommunicationAssociationModel {
    if (!apiData) {
      return new CommunicationAssociationModel();
    }
    const data: Partial<CommunicationAssociationModel> = {
      ...apiData,
      id: apiData.customerCommunicationAssociationId || apiData.id,
      customer: CustomerRefModel.deserialize(apiData.customer),
      offices: apiData.customerCommunicationAssociatedOffices?.map(
        entity =>
          new EntityMapModel({
            id: entity.customerCommunicationAssociatedOfficeId,
            entityId: entity.customerAssociatedOffice.customerAssociatedOfficeId,
            name: entity.customerAssociatedOffice.associatedOfficeName,
          })
      ),
      operators: apiData.customerCommunicationAssociatedOperators?.map(
        entity =>
          new EntityMapModel({
            id: entity.customerCommunicationAssociatedOperatorId,
            entityId: entity.customerAssociatedOperator.customerAssociatedOperatorId,
            name: entity.customerAssociatedOperator.operator.name,
          })
      ),
      registries: apiData.customerCommunicationAssociatedRegistries?.map(
        entity =>
          new EntityMapModel({
            id: entity.customerCommunicationAssociatedRegistryId,
            entityId: entity.customerAssociatedRegistry.customerAssociatedRegistryId,
            name: entity.customerAssociatedRegistry.registry.name,
          })
      ),
      sites: CustomerCommunicationAssociatedSitesModel.deserializeList(apiData.customerCommunicationAssociatedSites),
    };
    return new CommunicationAssociationModel(data);
  }

  static deserializeList(apiDataList: ICommunicationAssociation[]): CommunicationAssociationModel[] {
    return apiDataList ? apiDataList.map(apiData => CommunicationAssociationModel.deserialize(apiData)) : [];
  }

  public serialize(): ICommunicationAssociationRequest {
    return {
      id: this.id || 0,
      customerNumber:this.customer?.number,
      customerName: this.customer?.name,
      partyId:this.customer?.partyId,
      customerOperatorCommunicationAssociations: this.operators?.map(x => x.entityId) || [],
      customerRegistryCommunicationAssociations: this.registries?.map(x => x.entityId)|| [],
      customerOfficeCommunicationAssociations: this.offices?.map(x => x.entityId)|| [],
      customerSiteCommunicationAssociations: this.sites?.map(x => x.serialize()),
      ...this._serialize(),
    };
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
